body {
  font-family: arial, sans-serif;
  color: #4d5156;
}
.cursor-pointer {
  cursor: pointer;
}
.focus-unborder:focus {
  box-shadow: unset !important;
}
.pcoded-header {
  position: fixed;
  top: 0;
  margin-left: 0;
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
// .pcoded-main-container {
//   margin-top: 90px;
//   margin-left: 0;
// }
.form-search-home {
  form {
    width: 50%;
    position: fixed;
    top: 12px;
  }
  @media only screen and (max-width: 992px) {
    padding-left: 0 !important;
    form {
      padding: 10px;
      position: unset;
      background: #fff;
      width: 100%;
      padding-bottom: 4px;
    }
  }

  .list-group-item {
    border: unset;
  }
  line-height: unset !important;
}

.group-input-search {
  border: 1px solid #dfe1e5;
  &:hover {
    border-color: rgba(223, 225, 229, 0);
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  }
  input {
    font-family: inherit;
    color: #000000ff;
  }
}
.nav-link-custom {
  line-height: 1.3;
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: 400;
  color: #1a0dab;
  font-family: arial, sans-serif;

  &:hover {
    text-decoration: underline;
  }
}
.result-stats {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Google Sans, arial, sans-serif;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 8px;
  color: #70757a;
}
.content-manual-search {
  color: #4d5156;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: 1.58;

  em {
    color: #5f6368;
    font-weight: bold;
    font-style: normal;
  }
}
.breeak-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.content-manual-detail {
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 400;
  letter-spacing: 0.005em;
  font-family: arial, sans-serif;
}
footer a {
  color: #5f6368;
}
.title-detail {
  font-size: 18pt;
  line-height: 40px !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
  color: #171940;
  font-family: arial, sans-serif;
}
.vh-90 {
  min-height: 90vh;
}
.vh-mb-80 {
  @media only screen and (max-width: 992px) {
    min-height: 78vh;
  }
}
.form-input-search-home {
 // padding-top: 12%;
  @media only screen and (max-width: 992px) {
    //padding-top: 45%;
    > div {
      width: 100% !important;
    }
  }
}
.description-manual img{
  max-width: 100%;
}

.form-search-home-center{
  height: calc(100vh - 68px);

  @media only screen and (max-width: 992px) {
    //padding-top: 45%;
    height: calc(100vh - 200px);
  }
}
.three-dot-2-line{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}